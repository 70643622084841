import { buildRouteWithGraphPrefetch, PageConcurrentPrefetchProps } from "@emibee/lib-app-common";
import { RwfPermission } from "@emibee/lib-shared";
import { RwfPrivilegeMH, SecurityAttributeScope } from "@mh/common";
import GavelIcon from "@mui/icons-material/Gavel";
import { buildDateRangeFetchMoreArgsFromPreset } from "../../controls/view/useFetchMoreFilter";
import { KeepAliveOwner, MHClientService } from "../../core/MHClientService";
import { defaults, Domains, domainView } from "../../core/textResourceScopes";
import { AuctionListItemDataKit, IAuctionListItemUI } from "../../data/auction";
import { AuctionDetailsDataKit, IAuctionDetailsUI } from "../../data/auction/AuctionDetails";
import { getSecurityAttributeScope } from "../../tools/AuctionTools";
import { textResources } from "./testResources";

export enum AuctionDetailsTabs {
  // auction,
  car,
  actions,
  bids,
  details,
  visitors,
  starred,
  audit
}

export interface AuctionDetailsConcurrentProps extends PageConcurrentPrefetchProps<IAuctionDetailsUI> {
  initTab?: AuctionDetailsTabs;
  auctionId?: string;
  securityScope?: SecurityAttributeScope;
}

export const ENDED_AUCTION_LIST_RANGE = 5;

export const AuctionDetailsRoute = buildRouteWithGraphPrefetch({
  path: "/auctionDetails",
  displayText: textResources.detailsPageTitle,
  icon: <GavelIcon />,
  auth: true,

  resources: {
    lazy: () => import("./AuctionDetailsPage"),
    localeNamespaces: [domainView(Domains.auction), defaults()],
    prefetchGraph: (args?: AuctionDetailsConcurrentProps) => ({
      query: AuctionDetailsDataKit.queries.getAuctionDetails,
      args: { id: args?.auctionId!, securityScope: SecurityAttributeScope.max }
    })
  },
  params: ["auctionId", "initTab"],
  privilege: RwfPrivilegeMH.Auction,
  permission: RwfPermission.read
});

export type AuctionListConcurrentProps = PageConcurrentPrefetchProps<IAuctionListItemUI[]>;

export const AuctionListRoute = buildRouteWithGraphPrefetch({
  path: "/auctionList",
  displayText: textResources.auctionListRouteText,
  icon: <GavelIcon />,
  auth: true,
  resources: {
    lazy: () => import("./AuctionListPage"),
    localeNamespaces: [domainView(Domains.auction), defaults()],
    prefetchGraph: (_p, client) => ({
      query: AuctionListItemDataKit.queries.getAuctionList,
      args: AuctionListItemDataKit.buildDateRangeFetchMoreArgs(
        "auctionEndTime",
        buildDateRangeFetchMoreArgsFromPreset({ thisMonth: true }),
        {
          securityScope: getSecurityAttributeScope((client as MHClientService).viewAccount?.userType)
        }
      ),
      watchQuery: true,
      keepAlive: KeepAliveOwner.AuctionList
    })
  },
  privilege: RwfPrivilegeMH.Auction,
  permission: RwfPermission.read
});

export const EndedAuctionListRoute = buildRouteWithGraphPrefetch({
  path: "/endedAuctions",
  displayText: textResources.endedAuctionListRouteText,
  icon: <GavelIcon />,
  auth: true,
  resources: {
    lazy: () => import("./AuctionListPage"),
    localeNamespaces: [domainView(Domains.auction), defaults()],
    prefetchGraph: (_p, client) => ({
      query: AuctionListItemDataKit.queries.getAuctionList,
      args: {
        ...AuctionListItemDataKit.buildDateRangeFetchMoreArgs(
          "auctionEndTime",
          buildDateRangeFetchMoreArgsFromPreset({ latestDaysTilNow: ENDED_AUCTION_LIST_RANGE }),
          {
            securityScope: getSecurityAttributeScope((client as MHClientService).viewAccount?.userType)
          }
        )
      },
      watchQuery: true,
      keepAlive: KeepAliveOwner.EndedAuctionList
    })
  },
  privilege: RwfPrivilegeMH.Auction,
  permission: RwfPermission.read
});
// export type SalesAuctionListConcurrentProps = PageConcurrentPrefetchProps<SalesAuctionListItem[]>;

// export const SalesAuctionListRoute = buildRouteWithGraphPrefetch({
//   path: "/salesAuctionList",
//   displayText: textResources.salesListRouteText,
//   icon: <FactCheck />,
//   auth: true,
//   resources: {
//     lazy: () => import("./SalesAuctionListPage"),
//     localeNamespaces: [domainView(Domains.auction), defaults()],
//     prefetchGraph: (_p, client) => ({
//       query: SalesAuctionListDataKit.queries.getSalesAuctionList,
//       args: SalesAuctionListDataKit.buildDateRangeFetchMoreArgs(
//         "auctionEndTime",
//         buildDateRangeFetchMoreArgsFromPreset({ middleOfDaysRange: 14 }),
//         { securityScope: getSecurityAttributeScope((client as MHClientService).viewAccount?.userType) }
//       ),
//       watchQuery: true,
//       keepAlive: KeepAliveOwner.SalesAuctionList
//     })
//   },
//   privilege: RwfPrivilegeMH.Auction,
//   permission: RwfPermission.read
// });
