import {
  buildGraphRuQuerySet,
  buildSelectOptions,
  buildTextResources4Data,
  buildTextResources4Enum,
  CommonFieldScopes,
  CommonResponse,
  dataKitBuilder,
  FileRefDataKit,
  GraphQueryType,
  IFileRef,
  OrganizationDataKitFieldsBuilder,
  OrganizationDataKitQueryBuilder,
  PrivilegedUnitDataKitBuilder
} from "@emibee/lib-app-common";
import { GetOrganizationArgs, IAccount, MultiSelectFetchMoreArgs } from "@emibee/lib-shared";
import {
  BlockUserArgs,
  ChangeOrgTypeArgs,
  ContactInfoContract,
  ContactRole,
  CountryCode,
  IUserMin,
  OrganizationMHContract,
  OrganizationSettings,
  OrganizationType,
  PostalAddress
} from "@mh/common";
import { dataNamespace, Domains, enumNamespace } from "../core/textResourceScopes";
import { PostalAddressDataKit } from "./organization/postalAddress";
import { UserMinDataKit } from "./user/UserMin";

export enum NotificationTypes {
  newAuctions,
  endedAuctions
}

export const ContactRoleTextResources = buildTextResources4Enum<typeof ContactRole>({
  scope: "Data",
  namespace: enumNamespace(Domains.organization, "ContactRole"),
  resources: {
    primaryContact: "Primary Contact",
    billingAddressee: "Billing Addressee"
  }
});

export const NotificationTypeTextResources = buildTextResources4Enum<typeof NotificationTypes>({
  scope: "Data",
  namespace: enumNamespace(Domains.organization, "NotificationType"),
  resources: {
    newAuctions: "New Auctions",
    endedAuctions: "Ended Auctions"
  }
});

export const OrganizationTypeTextResources = buildTextResources4Enum<typeof OrganizationType>({
  scope: "Data",
  namespace: enumNamespace(Domains.organization, "OrganizationType"),
  resources: {
    other: "Other",
    reseller: "Reseller",
    dealer: "Dealer"
  }
});

export type ContactAccountType = Pick<
  IUserMin,
  "email" | "title" | "id" | "firstname" | "lastname" | "phone" | "vDisplayName" | "username"
>;

export interface ContactInfo extends ContactInfoContract {
  account?: ContactAccountType;
}

const contactInfoTextResources = buildTextResources4Data<ContactInfo>({
  scope: "Data",
  namespace: dataNamespace(Domains.organization, "ContactInfo"),
  resources: {
    account: "Account",
    accountId: "Account ID",
    email: "Email",
    name: "Name",
    phone: "Phone",
    roles: "Contact Roles",
    inviteRequested: "Send invitation",
    invitedWhen: "Invited"
  }
});

export const ContactInfoDataKit = dataKitBuilder(contactInfoTextResources)
  .field("account")
  .props({
    complex: {
      fields: UserMinDataKit.selectFields("id", "email", "phone", "firstname", "lastname", "title", "vDisplayName")
    },
    scope: CommonFieldScopes.max
  })
  .field("roles")
  .props({ options: buildSelectOptions(ContactRole, ContactRoleTextResources) })
  .field("inviteRequested")
  .props({ virtual: true })
  .build();

export const SettingsTextResources = buildTextResources4Data<OrganizationSettings>({
  scope: "Data",
  namespace: dataNamespace(Domains.organization, "Settings"),
  resources: {
    dealerFee: "Dealer Fee",
    noExportCars: "No Export",
    preventBidsBelowStart: "No Bids below start",
    canAccessLeadMgmt: "Lead Mgmt.",
    doesNotCollectFee: "MotorHammer bills",
    enableSingleAuction: "Single Auctions",
    enableBundleAuction: "Bundle Auction",
    enableDutchAuction: "Dutch Auction",
    enableAdminAuction: "Admin Auction",

    dekraOrgUnitID: "Dekra Org Unit ID",
    dekraOrgUnitRootID: "Dekra Org Unit Root",
    blocklist: "Blacklist",
    extraFee: "Extra Fee"
  }
});

export const SettingsDataKit = dataKitBuilder(SettingsTextResources)
  .fieldType("number", "dealerFee")
  .fieldType(
    "boolean",
    "enableSingleAuction",
    "enableBundleAuction",
    "enableDutchAuction",
    "enableAdminAuction",
    "noExportCars",
    "doesNotCollectFee",
    "preventBidsBelowStart",
    "extraFee"
  )
  .field("blocklist")
  .props({ complex: UserMinDataKit })
  .build();

export interface OrganizationMH extends OrganizationMHContract<string, IAccount, ContactInfo> {
  files?: IFileRef[];
  parentOrg?: this;
  subUnits?: this[];
  //@David: warum die virtuellen im DataKit und nicht im Builder?
  vZip?: string;
  vCity?: string;
  vCountry?: string;
  vEmail?: string;
  vPhone?: string;
}

export interface CreateOrganizationArgsMH extends Partial<OrganizationMH> {}

export const OrganizationMHFieldsDKBuilder = OrganizationDataKitFieldsBuilder()
  .extend<OrganizationMH>({
    owner: "Owner",
    branch: "Branch",
    vatId: "VAT ID",
    taxId: "Tax ID",
    registrationNumber: "Registration No.",
    files: "Files",
    mainAddress: "Main Address",
    contacts: "Contacts",
    billingAddress: "Billing Address",
    billingDetails: "Billing Address",
    orgType: "Organization Type",
    affiliateCode: "Affiliate Code",
    approval: "Approved",
    externalId: "External ID",
    keyAccountId: "Key-Account ID",
    keyAccountDisplayName: "Key-Account",
    provisionAccountId: "Provision-Account ID",
    vZip: "Zip",
    vCity: "City",
    vCountry: "Country",
    vEmail: "eMail",
    vPhone: "Phone",
    settings: "Settings",
    enableAdminAuction: "enableAdminAuction",
    enableDutchAuction: "enableDutchAuction"
  })

  .virtualField("vZip")
  .props({
    valueGetter: p => (p.mainAddress ? p.mainAddress.zip : "")
  })
  .virtualField("vCity")
  .props({
    valueGetter: p => (p.mainAddress ? p.mainAddress.city : "")
  })
  .virtualField("vCountry")
  .props({
    valueGetter: p => (p.mainAddress ? CountryCode[p.mainAddress.countryCode as keyof typeof CountryCode] : "")
  })
  .virtualField("vEmail")
  .props({
    valueGetter: p => {
      const contactUser = p.contacts?.find(c => c.roles?.includes(ContactRole.primaryContact));
      if (contactUser?.account || contactUser?.email) {
        return contactUser?.account ? contactUser.account.email : contactUser?.email;
      } else {
        return p.owner?.account ? p.owner.account.email : p.owner?.email;
      }
    }
  })
  .virtualField("vPhone")
  .props({
    valueGetter: p => {
      const contactUser = p.contacts?.find(c => c.roles?.includes(ContactRole.primaryContact));
      if (contactUser?.account || contactUser?.phone) {
        return contactUser?.account ? contactUser.account.phone : contactUser?.phone;
      } else {
        return p.owner?.account ? p.owner.account.phone : p.owner?.phone;
      }
    }
  })
  .field("files")
  .props({ complex: FileRefDataKit, scope: CommonFieldScopes.max })
  .field("orgType")
  .props({ options: buildSelectOptions(OrganizationType, OrganizationTypeTextResources) })
  .field("mainAddress")
  .props({ complex: PostalAddressDataKit, scope: CommonFieldScopes.max })
  .field("billingAddress")
  .props({ complex: PostalAddressDataKit, scope: CommonFieldScopes.max })
  .field("owner")
  .props({ complex: ContactInfoDataKit, complexDeep: true, scope: CommonFieldScopes.max })
  // .field("contact")
  // .props({ complex: ContactInfoDataKit, complexDeep: true, scope: CommonFieldScopes.max })
  .field("contacts")
  .props({ complex: ContactInfoDataKit, complexDeep: true, scope: CommonFieldScopes.max })
  .field("settings")
  .props({ complex: SettingsDataKit, complexDeep: true, scope: CommonFieldScopes.max });

function buildDataKit() {
  return OrganizationDataKitQueryBuilder<OrganizationMH, CreateOrganizationArgsMH>(
    OrganizationMHFieldsDKBuilder
  ).extend<{
    getOrganizations: GraphQueryType<CommonResponse<OrganizationMH[]>>;
    blockUsers: GraphQueryType<CommonResponse<OrganizationMH>, BlockUserArgs>;
    changeOrgType: GraphQueryType<CommonResponse<OrganizationMH>, ChangeOrgTypeArgs>;
  }>(qb => ({
    getOrganization: qb
      .query("getOrganization")
      .args<GetOrganizationArgs>("GetOrganizationInput", true)
      .Result.allFields(true, CommonFieldScopes.max)
      .subBuilder("mainAddress", builder => builder.allFields(true))
      .subBuilder("owner", builder =>
        builder
          .customType<Partial<ContactInfo>>()
          .selectFields("email", "name", "account", "accountId")
          .subBuilder(
            "account",
            builder =>
              builder
                .customType<Partial<IUserMin>>()
                .selectFields("email", "name", "id", "phone", "vDisplayName", "username", "firstname", "lastname")
            // .selectFields("email", "name", "id")
          )
      )
      .subBuilder("contacts", builder =>
        builder
          .customType<Partial<ContactInfo>>()
          .selectFields("email", "name", "account", "accountId", "roles", "phone")
          .subBuilder("account", builder =>
            builder
              .customType<Partial<IUserMin>>()
              .selectFields("email", "name", "id", "phone", "vDisplayName", "username", "firstname", "lastname")
          )
      )
      .build(),
    getOrganizations: qb
      .query("getOrganizations", { includeCV: true })
      .args<MultiSelectFetchMoreArgs<{}, OrganizationType>>("")

      .incrementalFetch("MultiSelectN")
      .ListResult.selectFields(
        "id",
        "name",
        "branch",
        "description",
        "orgType",
        "owner",
        "contacts",
        "registrationNumber",
        "taxId",
        "vatId",
        "keyAccountId",
        "keyAccountDisplayName"
      )
      .subBuilder("mainAddress", builder =>
        builder.customType<Partial<PostalAddress>>().selectFields("zip", "city", "countryCode")
      )
      .subBuilder("owner", builder =>
        builder
          .customType<Partial<ContactInfo>>()
          .selectFields("email", "name", "account", "accountId")
          .subBuilder("account", builder => builder.customType<Partial<IUserMin>>().selectFields("email", "name"))
      )
      .subBuilder("contacts", builder =>
        builder
          .customType<Partial<ContactInfo>>()
          .selectFields("email", "name", "account", "roles")
          .subBuilder("account", builder => builder.customType<Partial<IUserMin>>().selectFields("email", "name"))
      )
      // .subBuilder("settings", builder =>
      //   builder.customType<Partial<OrganizationSettings>>().selectFields("doesNotCollectFee")
      // )
      .build(),
    blockUsers: qb
      .mutation("blockUsers")
      .args<BlockUserArgs>("BlockUsersInput", true)
      .Result.selectFields("id", "cid", "settings")
      .subBuilder("settings", builder =>
        builder.allFields(true).subBuilder("blocklist", builder => builder.selectFields("id", "vDisplayName", "email"))
      )

      .build(),
    changeOrgType: qb
      .mutation("changeOrgType")
      .args<ChangeOrgTypeArgs>("changeOrgTypeInput", true)
      .Result.allFields(false)
      .build()
  }));
}

export const OrganizationMHDataKit = buildDataKit();

export const PrivilegedUnitDataKit = PrivilegedUnitDataKitBuilder(OrganizationMHFieldsDKBuilder);

export const OrganizationGraphQuerySet = buildGraphRuQuerySet(
  OrganizationMHDataKit.queries.getOrganization,
  OrganizationMHDataKit.queries.changeOrganization,
  mapOrganizationToChangeInput
);

export function mapOrganizationToChangeInput(org: OrganizationMH) {
  const { files, ...args } = org;
  args.owner = fixContactInfo(args.owner)!;
  delete args.updatedBy;
  delete args.createdBy;
  delete args.parentOrg;
  delete args.isParentOrg;

  if (args?.settings?.blocklist?.length) {
    const filteredBlocklist = args.settings.blocklist.map(a => fixAccountToStingInput(a)).filter(a => a !== undefined);
    if (filteredBlocklist) {
      args.settings = { ...args.settings, blocklist: (filteredBlocklist as unknown) as IUserMin[] };
    }
  }

  if (args?.contacts?.length) {
    args.contacts = args.contacts.map(c => fixContactInfo(c)!);
  }
  return fixNullToUndefined(
    args,
    "displayName",
    "description",
    "affiliateCode",
    "externalId",
    "branch",
    "vatId",
    "taxId",
    "registrationNumber",
    "keyAccountId",
    "provisionAccountId",
    "billingDetails",
    "billingAddress",
    "parentOrgId"
  );
}

export function fixContactInfo(contactInfo: ContactInfo | undefined) {
  if (!contactInfo) {
    return undefined;
  } else if (contactInfo.account) {
    return { accountId: contactInfo.account.id, roles: contactInfo.roles };
  } else {
    return {
      email: contactInfo.email,
      name: contactInfo.name,
      phone: contactInfo.phone,
      roles: contactInfo.roles,
      inviteRequested: contactInfo.inviteRequested
    };
  }
}
export function fixAccountToStingInput(account: IUserMin | undefined) {
  if (!account) {
    return undefined;
  } else {
    return { _id: account.id };
  }
}

function fixNullToUndefined<T extends {}>(data: T, ...exceptNullables: (keyof T)[]) {
  if (!Object.isSealed(data)) {
    Object.entries(data).forEach(([key, val]) => {
      if ((val === null || val === "") && !exceptNullables?.includes(key as never)) {
        (data as any)[key] = undefined;
      } else if (Array.isArray(data)) {
        data.forEach(item => fixNullToUndefined(item));
      } else if (typeof val === "object" && val !== null) {
        fixNullToUndefined(val);
      }
    });

    // ensure nullables
    if (exceptNullables) {
      exceptNullables.forEach(key => {
        if (!data[key]) {
          data[key] = null as never;
        }
      });
    }
  }

  return data;
}

// export const OrganizationDataKit = OrganizationDataKitBuilder<OrganizationMH, CreateOrganizationArgsMH>();

// const organizationMHTextResources = OrganizationDataKit.extendTextResources<OrganizationMH>({
//   type: "Organization Type",
//   mainAddress: "Main",
//   additionalContacts: "Additional Contacts"
//   // parentOrg: "PO",
//   // subUnits: "SU"
// });

// export const OrganizationTypeTextResources = buildTextResources4Enum<typeof OrganizationType>({
//   scope: "Data",
//   namespace: enumNamespace(DataNames.organization, "OrganizationType"),
//   resources: {
//     reseller: "Reseller",
//     dealer: "Dealer"
//   }
// });

// export const OrganizationMHDataKit = OrganizationDataKit.extend(organizationMHTextResources)

//   .field("type")
// .props({ options: buildSelectOptions(OrganizationType, OrganizationTypeTextResources) })
// .field("mainAddress")
// .props({ complex: PostalAddressDataKit })
// .field("additionalContacts")
// .props({ complex: ContactInfoDataKit })

//   // todo: WORKAROUND since extended DataKit does not contain queries
//   .queries(qb => ({
//     getOrganizations: qb
//       .query("getOrganizations")
//       .args()
//       .ListResult.allFields(true)
//       .build(),
//     getOrganization: qb
//       .query("getOrganization")
//       .args<GetOrganizationArgs>("GetOrganizationInput", true)
//       .refetchOn("createOrganization")
//       .Result.allFields(true, CommonFieldScopes.max)
//       .build(),
//     createOrganization: qb
//       .mutation("createOrganization")
//       .args<CreateOrganizationArgsMH>("CreateOrganizationInput", true)
//       .Result.allFields(true)
//       .build(),
//     changeOrganization: qb
//       .mutation("changeOrganization")
//       .args<ChangeOrganizationArgs<OrganizationMH>>("ChangeOrganizationInput", true)
//       .Result.allFields(true)
//       .build(),
//     deleteOrganization: qb
//       .mutation("deleteOrganization")
//       .args<CommonDeleteArgs>("CommonDeleteInput", true)
//       .Result.allFields(true)
//       .build(),
//     inviteMember: qb
//       .mutation("inviteMember")
//       .args<InviteMemberArgs>("InviteMemberInput", true)
//       .EmptyResult.build(),
//     verifyInviteMemberToken: qb
//       .mutation("verifyInviteMemberToken")
//       .args<VerifyInviteMemberTokenArgs>("VerifyInviteMemberTokenInput", true)
//       .Result.customType<{ email: string }>()
//       .selectFields("email")
//       .build()
//   }))
//   // WORKAROUND END
//   .build();

// export interface OrganizationMH extends OrganizationMHContract<string, IAccount>, Organization {
//   // parentOrg?: OrganizationMH;
//   // subUnits?: OrganizationMH[];
//   // orgType: OrganizationType;
// }
//
// //
// // export interface OrganizationMH extends OrganizationMHContract<string, IAccount> {
// //   orgType: OrganizationType;
// // }
//
//
//
//
// export const OrganizationDataKit = OrganizationDataKitBuilder<OrganizationMH, CreateOrganizationArgsMH>();
//
// const organizationMHTextResources = OrganizationDataKit.extendTextResources<OrganizationMH>({
//   vatId: "vatId"
//   // parentOrg: "Parent Organization",
//   // subUnits: "Subunits"
// });

// export const OrganizationMHDataKit = dataKitBuilder(organizationMHTextResources).build();

// export interface CreateOrganizationArgsMH extends Partial<OrganizationMH> {}

// export function useOrganizationApiMH(org?: ControlInitialData<OrganizationMH>, attachParent?: boolean) {
//   return useOrganizationApi<OrganizationMH, CreateOrganizationArgsMH>(org, attachParent);
// }

// export type OrganizationApiMH = OrganizationApi<OrganizationMH, CreateOrganizationArgsMH>;
