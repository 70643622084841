import { buildRouteWithGraphPrefetch, PageConcurrentPrefetchProps } from "@emibee/lib-app-common";
import { DateRangeFetchMoreArgs, RwfPermission } from "@emibee/lib-shared";
import { GetCarListArgs, ICarV2, RwfPrivilegeMH } from "@mh/common";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { KeepAliveOwner, MHClientService } from "../../core/MHClientService";
import { ControlNames, controls, dataNamespace, defaults, Domains, domainView } from "../../core/textResourceScopes";
import { CarDetailsDataKit } from "../../data/car/CarDetails";
import { CarListItem, CarListItemDataKit } from "../../data/car/CarListItem";
import { textResources } from "./textResources";

export interface ExCarDetailsConcurrentProps extends PageConcurrentPrefetchProps<ICarV2> {
  id: string;
}
export interface ExCarListConcurrentProps extends PageConcurrentPrefetchProps<CarListItem[]> {
  carId?: string;
  filter?: string;
}

export const ExCarDetailsRoute = buildRouteWithGraphPrefetch({
  path: "/exCarDetails",
  displayText: textResources.detailsPageTitle,
  icon: <DirectionsCarIcon />,
  auth: true,
  resources: {
    lazy: () => import("./ExCarDetailsPage"),
    localeNamespaces: [
      domainView(Domains.car),
      defaults(),
      controls(ControlNames.common),
      dataNamespace(Domains.ui, "Formatters"),
      domainView(Domains.auction)
    ],
    prefetchGraph: (args?: ExCarDetailsConcurrentProps) => ({
      query: CarDetailsDataKit.queries.getCarDetails,
      args: { carId: args?.id },
      watchQuery: true
    })
  },
  params: ["id"],
  privilege: RwfPrivilegeMH.Car,
  permission: RwfPermission.read
});

export const ExCarListRoute = buildRouteWithGraphPrefetch<
  ExCarListConcurrentProps,
  CarListItem[],
  DateRangeFetchMoreArgs<GetCarListArgs>,
  void
>({
  path: "/exCarList",
  icon: <DirectionsCarIcon />,
  displayText: textResources.listPageTitle,
  auth: true,
  resources: {
    lazy: () => import("./list/ExCarListPage"),
    localeNamespaces: [domainView(Domains.auction), domainView(Domains.car), defaults()],
    prefetchGraph: (_args, client) => ({
      query: CarListItemDataKit.queries.getCarList,
      args: CarListItemDataKit.emptyFetchMoreArgs({
        ownerOrgId: (client as MHClientService).activeOrgId,
        includeChildOrgs: (client as MHClientService).activeOrg?.isParentOrg
      }),
      keepAlive: KeepAliveOwner.ExCarList,
      watchQuery: true
    })
  },
  params: ["carId", "filter"],
  privilege: RwfPrivilegeMH.Car,
  permission: RwfPermission.read
});
