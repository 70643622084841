import { FetchPolicy, InMemoryCache } from "@apollo/client";
import {
  ChangeScope,
  ClientService,
  CommonResponse,
  CommonResponseResultType,
  FallbackFeedbackHandler,
  FeedbackHandler,
  GraphQueryType,
  IClientLogger,
  INotificationService,
  isClientException,
  NotificationService,
  ProfileService,
  registerTextResourceExporter,
  TextResDataKit,
  TextResourcesDefinition
} from "@emibee/lib-app-common";
import { GraphQueryArgs, PrivilegedUnitKind, RwfPermission } from "@emibee/lib-shared";
import { IUserMin, MHPrivileges, OrganizationType, UIPrivilegeMH } from "@mh/common";
import i18n from "i18next";
import { Subject } from "rxjs";
import { bufferTime, filter } from "rxjs/operators";
import { AuctionDetailsDataKit } from "../data/auction/AuctionDetails";
import { CarListItemDataKit } from "../data/car";
import { OrganizationMH, PrivilegedUnitDataKit } from "../data/organization";
import { SignInDataKit, UserOverviewDataKit } from "../data/user";
import { authorize, Privileges } from "./authorization";
import { DataGridViewItem } from "./common";
import { NotificationDispatcher } from "./NotificationService";
import { TransactionManager } from "./transactions";

export const PwdHashDomain = "motorhammer.com";
const C_MHClientService = "MHClientService";

export enum KeepAliveOwner {
  AccountsWidget = "AccountsWidget",
  AuctionList = "AuctionList",
  CarList = "CarList",
  EndedAuctionList = "EndedAuctionList",
  ExCarList = "ExCarList",
  ExAuctionList = "ExAuctionList",
  SalesAuctionList = "SalesAuctionList",
  AuctionArchiveList = "AuctionArchiveList",
  OrganizationList = "OrganizationList",
  AccountList = "AccountList"
}
export type ChangeScopeMH = ChangeScope | "ActingOrg";

interface CoreProfile {
  activeOrgId?: string;
}
export interface DataGridProfile {
  selId?: number;
  views?: DataGridViewItem[];
}

interface ProfileScopeDefinition {
  core: CoreProfile;
  datagrid: Record<string, DataGridProfile>;
  widgetFilter: Record<string, number[]>;
}

export class MHClientService extends ClientService<IUserMin, IClientLogger> {
  // private _initialized = false;
  // private _initResult: ObservedChanges | undefined;
  // private _apolloClient: ApolloClient<unknown>;
  private _actingOrg?: OrganizationMH;
  // private _onStateChangeObservable = new Subject<ObservedChanges>();
  // private _services = new Map<string,any>();
  private _tm = new TransactionManager(this);

  constructor(uri: string, subscriptionUri: string) {
    super({
      serverUri: uri,
      serverUriSubscriptions: subscriptionUri,
      name: C_MHClientService,
      signInMutation: SignInDataKit.queries.signIn,
      checkSignedInQuery: SignInDataKit.queries.me,
      pwdHashDomain: PwdHashDomain
    });
    this.registerService(new NotificationDispatcher());
    this.registerService(new ProfileService());

    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_TEXT_RES !== "false") {
      const subject$ = new Subject<TextResourcesDefinition>();
      subject$
        .pipe(
          bufferTime(5000),
          filter(data => data.length > 0)
        )
        .subscribe(data => {
          this.mutate(TextResDataKit.queries.notifyTextResourceDefinitions, { data });
        });
      registerTextResourceExporter(textResources => {
        subject$.next(textResources);
        //  console.log("exportTextResources", textResources.scope, textResources.namespace, textResources.source);
      });
    } else {
      // deactivate
      registerTextResourceExporter(null);
    }

    (this.apollo.cache as InMemoryCache).policies.addTypePolicies({
      LastBid: { keyFields: false },
      AuctionStatistics: { keyFields: false },
      BidderInfo: { keyFields: false },
      Organization: {
        fields: {
          mainAddress: {
            merge(existing = {}, incoming: object) {
              return { ...existing, ...incoming };
            }
          }
          // settings: {
          //   merge(existing = {}, incoming: object) {
          //     return { ...existing, ...incoming };
          //   }
          // }
        }
      }
    });
  }

  get profileService() {
    return this.get(ProfileService.getId<ProfileScopeDefinition>());
  }

  private _tmpActiveOrgId?: string;
  get activeOrgId() {
    return this._actingOrg?.id ?? this._tmpActiveOrgId ?? this.user?.account.ownerOrgId;
  }
  get activeOrg() {
    return this._actingOrg ?? this.user?.account.ownerOrg;
  }
  get actingOrganization() {
    return this._actingOrg ?? null;
  }

  set actingOrganization(value: OrganizationMH | null) {
    if (this._actingOrg !== (value ?? undefined)) {
      this._actingOrg = value ?? undefined;
      this.notifyChange<ChangeScopeMH>("ActingOrg");
      this.profileService.setScope("core", { activeOrgId: this._actingOrg?.id });
      this._onActingOrganizationChanged();
    }
  }

  private _onActingOrganizationChanged() {
    if (
      this.user?.account.ownerOrg?.id === this.activeOrgId &&
      this.user?.account.ownerOrg?.orgType === OrganizationType.other
    ) {
      // macht nur sinn für MH, da dort alle permissions existieren (sollten)
      // sollte as mal andere Other-Organisationen geben müssen wir hier ran:
      this.QueryManagementService.registerSubscription(
        AuctionDetailsDataKit.queries.pendingAuctionChanged,
        undefined,
        AuctionDetailsDataKit.queries.getAuctionDetails
      );
      this.QueryManagementService.registerSubscription(CarListItemDataKit.queries.carChanged);
      this.QueryManagementService.registerSubscription(UserOverviewDataKit.queries.accountChanged);
    } else if (this.activeOrg) {
      // (re)-register subscriptions
      this.QueryManagementService.registerSubscription(
        AuctionDetailsDataKit.queries.pendingAuctionChanged,
        {
          orgId: this.activeOrg.id,
          includeChildOrgs: this.activeOrg.isParentOrg
        },
        AuctionDetailsDataKit.queries.getAuctionDetails
      );
      this.QueryManagementService.registerSubscription(CarListItemDataKit.queries.carChanged, {
        orgId: this.activeOrg.id,
        includeChildOrgs: this.activeOrg.isParentOrg
      });
    }
  }

  get multiOrganizations() {
    return !!this.user?.account.extPrivUnitIds?.length || this.authorize(UIPrivilegeMH.AppV2, RwfPermission.full);
  }

  protected onViewAsUserChanged(): void {
    this.actingOrganization = null;
  }

  get locale() {
    return i18n.language;
  }

  public queryBackground<T extends CommonResponse<any>, V extends GraphQueryArgs | void>(
    query: GraphQueryType<T, V>,
    args: V,
    fetchPolicy?: FetchPolicy
  ): Promise<CommonResponseResultType<T> | undefined> {
    return this._tm
      .executeSingleQuery(() => this.query(query, args, { fetchPolicy }), `Query '${query.name}'`, false)
      .then(r => r && r.result);
  }

  protected onNotifyError(error: unknown) {
    error = isClientException(error) ? `${error.context ?? "?"}: ${error.message}` : error;
    this.get(NotificationService).error(error);
  }
  protected onSignedOut(): void {
    super.onSignedOut();
    this._actingOrg = undefined;
    this.profileService.setScope("core", { activeOrgId: undefined });
  }

  protected onSignedIn(): void {
    this._tmpActiveOrgId = this.profileService.getScope("core")?.activeOrgId;
    if (this._tmpActiveOrgId && this.user) {
      this._resolveActiveOrgId(this._tmpActiveOrgId, this.user?.account.id)
        .then(org => {
          if (org) {
            this.actingOrganization = org;
          }
        })
        .finally(() => {
          this._tmpActiveOrgId = undefined;
        });
    } else {
      this._onActingOrganizationChanged();
    }
  }

  protected getFallbackFeedbackHandler(query: GraphQueryType<any, any>): FallbackFeedbackHandler {
    let feedbackHandler: FeedbackHandler | undefined = undefined;
    return {
      continue: async (error, retryHandle) => {
        return feedbackHandler ? feedbackHandler.continue(error, retryHandle) : false;
      },
      onAttempt: (error, remainingRetries, totalAttempts) => {
        if (feedbackHandler) feedbackHandler.onAttempt(error, remainingRetries, totalAttempts);
        else if (error) {
          this.get(NotificationService).error(error);
        }
      },
      attempts: () => (feedbackHandler?.attempts ? feedbackHandler.attempts() : 1),
      interval: () => (feedbackHandler?.interval ? feedbackHandler.interval() : 1000),
      setFeedbackHandler: fh => {
        feedbackHandler = fh;
      }
    };
  }

  // public queryBg<T extends CommonResponse<any>, V = void>(
  //   query: GraphQueryType<T, V>,
  //   args: V,
  //   fetchPolicy: FetchPolicy = "no-cache"
  // ): Promise<T | undefined> {
  //   return this._tm.executeSingleQuery(
  //     () => this.query(query, args, fetchPolicy),
  //     `Query '${query.name}'`,
  //     false
  //   );
  // }

  public mutateBackground<T extends CommonResponse<any>, V extends GraphQueryArgs | void = void>(
    query: GraphQueryType<T, V>,
    args: V
  ): Promise<CommonResponseResultType<T> | undefined> {
    return this._tm
      .executeSingleQuery(() => this.mutate(query, args), `Mutation '${query.name}'`, false)
      .then(r => r && r.result);
  }

  public handleError(error: string | Error) {
    this.get<INotificationService>(NotificationService).error(error);
  }

  public authorizeLegacy(privilege: Privileges) {
    return authorize(privilege, this.user?.account.securityRoles);
  }

  public authorize(privilege: MHPrivileges, permission?: RwfPermission) {
    return super.authorize(privilege, permission);
  }

  private _resolveActiveOrgId(orgId: string, accountId: string) {
    return this.query(PrivilegedUnitDataKit.queries.getPrivilegedUnitsMax, {
      accountId,
      kind: PrivilegedUnitKind.external
    }).then(res => {
      if (res.result) {
        const activeOrg = res.result.find(pu => !!pu.ownerOrg && pu.ownerOrgId === orgId)?.ownerOrg as OrganizationMH;
        return activeOrg;
      }
    });
  }
  // public authorize(privilege: string, permission?: number) {
  //   if (permission !== undefined) {
  //     return super.authorize(privilege, permission);
  //   } else {
  //     return authorize(privilege, this.user?.account.securityRoles);
  //   }
  // }
}
