import {
  AccountDataKit,
  buildSelectOptions,
  buildTextResources4Data,
  buildTextResources4Enum,
  CommonFieldScopes,
  dataKitBuilder,
  OrganizationDataKitFieldsBuilder,
  SignInArgs,
  SignInData
} from "@emibee/lib-app-common";
import { GetAccountArgs } from "@emibee/lib-shared";
import { IUserMin, OrganizationMHContract, PersonalTitle } from "@mh/common";
import { Domains, enumNamespace } from "../../core/textResourceScopes";
import { PostalAddressDataKit } from "../organization/postalAddress";
/**
 * UserMin
 */
// export interface UserMin extends IAccount {
//   title?: PersonalTitle;
//   phone?: string;
//   confirmed: boolean;
//   username: string;
//   firstname: string;
//   lastname: string;
//   securityRoles?: string[];
//   userType: UserType;
// }

const userMinTextResources = AccountDataKit.extendTextResources<IUserMin>({
  title: "Title",
  phone: "Phone",
  firstname: "Given Name",
  lastname: "Surname",
  username: "Username",
  securityRoles: "Roles",
  confirmed: "confirmed",
  userType: "Type",
  ownerOrg: "Organization"
});

export enum UserType {
  System_Admin = 100,
  Admin = 80,
  Dealer = 50,
  Reseller = 10
}
const userTypeTextResources = buildTextResources4Enum<typeof UserType>({
  scope: "Data",
  namespace: enumNamespace(Domains.account, "UserType"),
  resources: {
    Admin: "Admin",
    Dealer: "Dealer",
    Reseller: "Reseller",
    System_Admin: "SysAdmin"
  }
});

const userPersonalTitleTextResources = buildTextResources4Enum<typeof PersonalTitle>({
  scope: "Data",
  namespace: enumNamespace(Domains.account, "PersonalTitle"),
  resources: {
    Mr: "Mr",
    Ms: "Ms"
  }
});

const OrganizationMHFields = OrganizationDataKitFieldsBuilder()
  .extend<
    Omit<
      OrganizationMHContract,
      | "owner"
      | "approval"
      | "files"
      | "contacts"
      | "billingAddress"
      | "billingDetails"
      | "affiliateCode"
      | "externalId"
      | "keyAccountId"
      | "keyAccountDisplayName"
      | "provisionAccountId"
      | "settings"
    >
  >({
    orgType: "Organization Type",
    mainAddress: "Main Address",
    branch: "Branch",
    taxId: "Tax ID",
    vatId: "Vat ID",
    registrationNumber: "Registration Number",
    enableAdminAuction: "enableAdminAuction",
    enableDutchAuction: "enableDutchAuction"
  })
  .field("mainAddress")
  .props({
    complex: { fields: PostalAddressDataKit.fields }
  })
  .build();

export const UserMinDataKit = AccountDataKit.extend(userMinTextResources)
  .field("userType")
  .props({
    options: buildSelectOptions(UserType, userTypeTextResources)
  })
  .field("title")
  .props({
    options: buildSelectOptions(PersonalTitle, userPersonalTitleTextResources)
  })
  .field("ownerOrg")
  .props({
    complex: { fields: OrganizationMHFields.fields as never },
    scope: CommonFieldScopes.max
  })
  .queries(qb => ({
    getAccountMax: qb
      .query("getAccount")
      .args<GetAccountArgs>("GetAccountInput", true)
      .Result.allFields(true, CommonFieldScopes.max)
      .subBuilder("ownerOrg", builder => builder.allFields(true))
      .build()
  }))
  .build();

// Das ist wohl ein TS Bug, denn ohne eine Generic-freie Definition geht es nicht
interface SignInDataMH extends SignInData<IUserMin> {
  account: IUserMin;
}

const signInTextResources = buildTextResources4Data<SignInDataMH>({
  scope: "Data",
  namespace: "Account",
  resources: {
    account: "Account",
    permissions: "Permissions",
    session: "Session"
  }
});

export const SignInDataKit = dataKitBuilder(signInTextResources)
  .field("account")
  // .props({ complex: { fields: UserMinDataKit.fields } })
  .props({ complex: { fields: UserMinDataKit.fields } })
  .queries(qb => ({
    me: qb
      .query("me", { allowNull: true })
      .args()
      .Result.selectFields("account", "session", "permissions")
      .subBuilder("account", builder =>
        builder
          .allFields(true)
          .subFields("ownerOrg", "id", "name", "orgType", "isParentOrg", "enableAdminAuction", "enableDutchAuction")
      )

      .subFields("session", "durationM", "csrfToken")
      .subFields("permissions", "perm", "priv")
      .build(),
    signIn: qb
      .mutation("signIn")
      .args<SignInArgs>("SignInInput", true)
      .Result.selectFields("account", "session", "permissions")
      .subBuilder("account", builder =>
        builder
          .allFields(true)
          .subFields("ownerOrg", "id", "name", "orgType", "enableAdminAuction", "enableDutchAuction")
      )
      .subFields("session", "durationM", "csrfToken")
      .subFields("permissions", "perm", "priv")
      .build()
  }))
  .build();
